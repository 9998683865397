export const ROUTES = {
  accountDetailsPage: {
    url: `/locospace/account-details`,
    regex: /\/locospace\/account-details/,
  },
  authPage: {
    url: `/auth`,
    regex: /\/auth/,
  },
  autoResetPasswordPage: {
    url: `/password/reset/:username/:verificationCode`,
  },
  autoVerifyPage: {
    url: `/verify/:username/:verificationCode`,
    regex: /\/verify\/(.+)\/([0-9]{0,10})$/,
  },
  blogPage: {
    url: `/blog`,
  },
  blogArticlePage: {
    url: `/blog/:slug`,
  },
  businessLeasingPage: {
    url: `/business-leasing`,
  },
  careersPage: {
    url: `/careers`,
  },
  carSearchPage: {
    url: '/car-leasing/search',
    regex: /\/car-leasing\/search/,
  },
  collectionsPage: {
    url: `/collections`,
    regex: /\/collections/,
    title: 'collections',
  },
  comparisonPage: {
    url: `/locospace/comparison`,
    regex: /\/locospace\/comparison/,
  },
  complaintsPage: {
    url: `/complaints`,
  },
  cookiePolicyPage: {
    url: `/cookie-policy`,
  },
  debugPage: {
    url: `/debug`,
  },
  electricVehiclesPage: {
    url: `/electric-car-leasing`,
  },
  ethicalCodeOfConductPage: {
    url: `/ethical-code-of-conduct`,
  },
  enquiriesPage: {
    url: `/locospace/enquiries`,
    regex: /\/locospace\/enquiries/,
  },
  enquiryLandingPage: {
    url: '/ad/deal/:adReference',
    regex: /\/ad\/deal\/([a-z0-9-]{0,36})$/,
  },
  enquiryLandingAltPage: {
    url: '/ad/deal-alt/:adReference',
    regex: /\/ad\/deal-alt\/([a-z0-9-]{0,36})$/,
  },
  faqsPage: {
    url: `/faqs`,
    regex: /\/faqs$/,
  },
  faqsArticlePage: {
    url: `/faqs/:article`,
  },
  forgotPasswordPage: {
    url: `/password`,
  },
  guidesArticlePage: {
    url: `/guides/:category/:article`,
    regex: /\/guides\/([a-z \w-]{0,50})\/([a-z \w-]{0,50})$/,
  },
  guidesPage: {
    url: `/guides`,
    regex: /\/guides/,
  },
  guidesCategoryPage: {
    url: `/guides/:category`,
    regex: /\/guides\/([a-z \w-]{0,50})$/,
  },
  homePage: {
    url: `/`,
    regex: /^\/$/,
  },
  leasingCategoriesPage: {
    url: `/leasing-categories`,
  },
  locoScorePage: {
    url: `/loco-score`,
  },
  loginPage: {
    url: `/login`,
  },
  logoutPage: {
    url: `/logout`,
    regex: /\/logout$/,
  },
  locoAiSearchPage: {
    url: '/loco-ai/search',
    regex: /\/loco-ai\/search/,
  },
  manufacturerLandingPage: {
    url: '/:vehicleType-leasing/:manufacturer',
    regex: {
      default: /\/car-leasing\/(?!search)([a-z \w-]{0,50})$/,
      withPageNumber: /\/car-leasing\/(?!search)([a-z \w-]{0,50})(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/(?!search)([a-z \w-]{0,50})$/,
      vanWithPageNumber: /\/van-leasing\/(?!search)([a-z \w-]{0,50})(\?page=([0-9]{1,10}))$/,
    },
  },
  newsletterSignUpPage: {
    url: `/newsletter/sign-up`,
  },
  partnerCriteriaPage: {
    url: `/partner-criteria`,
  },
  pcpSavingsPage: {
    url: '/pcp-savings',
  },
  personalCarLeasingPage: {
    url: '/personal-car-leasing',
  },
  privacyPolicyPage: {
    url: `/privacy-policy`,
  },
  priceAlertsPage: {
    url: `/locospace/price-alerts`,
    regex: /\/locospace\/price-alerts/,
  },
  radioPage: {
    url: '/radio',
  },
  rangeLandingPage: {
    url: '/:vehicleType-leasing/:manufacturer/:model',
    regex: {
      default: /\/car-leasing\/(?!search)([a-z \w-]{0,50})\/([a-z \w-]{0,60})$/,
      withPageNumber:
        /\/car-leasing\/(?!search)([a-z \w-]{0,50})\/([a-z \w-]{0,60})(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/(?!search)([a-z \w-]{0,50})\/([a-z \w-]{0,60})$/,
      vanWithPageNumber:
        /\/van-leasing\/(?!search)([a-z \w-]{0,50})\/([a-z \w-]{0,60})(\?page=([0-9]{1,10}))$/,
    },
  },
  referAFriendPage: {
    url: `/locospace/refer-a-friend`,
    regex: /\/locospace\/refer-a-friend/,
  },
  referAFriendTermsPage: {
    url: `/locospace/refer-a-friend/terms`,
  },
  referRegisterPage: {
    url: `/refer/:referralCode`,
    regex: /\/refer\/([a-z0-9 \w-]{0,20})$/,
  },
  registerPage: {
    url: `/register`,
  },
  rewardsPage: {
    url: `/locospace/rewards`,
    regex: /\/locospace\/rewards/,
  },
  rewardsAndReferralsPage: {
    url: `/rewards-and-referrals`,
    regex: /\/rewards-and-referrals$/,
  },
  rewardsTermsPage: {
    url: `/locospace/rewards/terms`,
  },
  savedSearchPage: {
    url: `/locospace/saved-searches`,
    regex: /\/locospace\/saved-searches/,
  },
  searchPage: {
    url: `/:vehicleType-leasing/search`,
    regex: {
      default: /\/car-leasing\/search$/,
      withPageNumber: /\/car-leasing\/search(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/search$/,
      vanWithPageNumber: /\/van-leasing\/search(\?page=([0-9]{1,10}))$/,
    },
  },
  searchPageWithReference: {
    url: '/:vehicleType-leasing/search/:reference',
    regex: {
      default: /\/car-leasing\/search\/([a-z0-9 \w-]{0,32})$/,
      withPageNumber: /\/car-leasing\/search\/([a-z0-9 \w-]{0,32})(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/search\/([a-z0-9 \w-]{0,32})$/,
      vanWithPageNumber: /\/van-leasing\/search\/([a-z0-9 \w-]{0,32})(\?page=([0-9]{1,10}))$/,
    },
  },
  searchPageWithManufacturer: {
    url: '/:vehicleType-leasing/search/:manufacturer',
    regex: {
      default: /\/car-leasing\/search\/([a-z \w-]{0,50})$/,
      withPageNumber: /\/car-leasing\/search\/([a-z \w-]{0,50})(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/search\/([a-z \w-]{0,50})$/,
      vanWithPageNumber: /\/van-leasing\/search\/([a-z \w-]{0,50})(\?page=([0-9]{1,10}))$/,
    },
  },
  searchPageWithModel: {
    url: '/:vehicleType-leasing/search/:manufacturer/:model',
    regex: {
      default: /\/car-leasing\/search\/([a-z \w-]{0,50})\/([a-z \w-]{0,60})$/,
      withPageNumber:
        /\/car-leasing\/search\/([a-z \w-]{0,50})\/([a-z \w-]{0,60})(\?page=([0-9]{1,10}))$/,
      vanDefault: /\/van-leasing\/search\/([a-z \w-]{0,50})\/([a-z \w-]{0,60})$/,
      vanWithPageNumber:
        /\/van-leasing\/search\/([a-z \w-]{0,50})\/([a-z \w-]{0,60})(\?page=([0-9]{1,10}))$/,
    },
  },
  termsPage: {
    url: `/terms`,
  },
  treatingCustomersFairlyPage: {
    url: `/treating-customers-fairly`,
  },
  tvPage: {
    url: '/tv',
    regex: /\/tv$/,
  },
  vanHomePage: {
    url: `/van-leasing`,
    regex: /\/van-leasing$/,
  },
  vanSearchPage: {
    url: '/van-leasing/search',
    regex: /\/van-leasing\/search/,
  },
  vehicleWithLeaseProfilePage: {
    url: `/:vehicleType-leasing/:manufacturer/:model/:derivative/:vehicleId/:leaseProfile`,
    regex: {
      car: /\/car-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})$/,
      van: /\/van-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})$/,
    },
  },
  vehicleWithLeaseProfileAndDealIdPage: {
    url: `/:vehicleType-leasing/:manufacturer/:model/:derivative/:vehicleId/:leaseProfile/:dealId`,
    regex: {
      car: /\/car-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})$/,
      van: /\/van-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})$/,
    },
  },
  vehicleConfigPage: {
    url: `/:vehicleType-leasing/:manufacturer/:model/:derivative/:vehicleId/:leaseProfile/:dealId/config`,
    regex: {
      car: /\/car-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/config$/,
      van: /\/van-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/config$/,
    },
    title: {
      car: 'car-config',
      van: 'van-config',
    },
  },
  vehicleDealsPage: {
    url: `/:vehicleType-leasing/:manufacturer/:model/:derivative/:vehicleId/:leaseProfile/:dealId/deals`,
    regex: {
      car: /\/car-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/deals$/,
      van: /\/van-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/deals$/,
    },
    title: {
      car: 'car-deals',
      van: 'van-deals',
    },
  },
  vehicleEnquiredPage: {
    url: `/:vehicleType-leasing/:manufacturer/:model/:derivative/:vehicleId/:leaseProfile/:dealId/enquired`,
    regex: {
      car: /\/car-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/enquired$/,
      van: /\/van-leasing\/([a-z \w-]{0,50})\/([a-z0-9 \w-]{0,50})\/([a-z \w-]{0,60})\/([0-9]{0,10})\/([0-9-]{0,15})\/([a-z \w-]{0,50})\/enquired$/,
    },
    title: {
      car: 'car-enquired',
      van: 'van-enquired',
    },
  },
  verifyEnquiryPage: {
    url: `/verify-enquiry/:type/:id`,
    regex: /\/verify-enquiry\/p|e\/([a-z0-9 \w-]{0,6})$/,
  },
  whyLeasePage: {
    url: `/why-lease`,
    regex: /\/why-lease$/,
  },
}
