export enum Theme {
  light = 'light',
  dark = 'dark',
}

export type ThemeType = Theme.light | Theme.dark

export enum TestStatus {
  Default = 'default',
  Fail = 'fail',
  Pass = 'pass',
}

export enum Size {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  ExtraExtraLarge = '2xl',
}

export type SizeType = Size.ExtraSmall | Size.Small | Size.Medium | Size.Large | Size.ExtraLarge | Size.ExtraExtraLarge

export enum LoadingStatus {
  Loading = 'loading',
  Complete = 'complete',
}

export type LoadingStatusType = LoadingStatus.Loading | LoadingStatus.Complete

export type ApiError = {
  key: number
  message: string
  status: number
}

export type PageType = 'config' | 'landing' | 'landing-alt'
