import { useEffect, useRef, useState } from 'react'
import Script from 'next/script'

import styles from './trustpilot.module.scss'
import { constants } from '@/lib/constants'

export type TrustpilotWidgetProps = {
  theme?: 'dark' | 'light'
}

export const TrustpilotStarWidget = ({ theme = 'light' }: TrustpilotWidgetProps) => {
  const widgetRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      { rootMargin: '0px 0px 100px 0px' },
    )
    if (widgetRef.current) {
      observer.observe(widgetRef.current)
    }
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (isVisible && window.Trustpilot && widgetRef.current) {
      window.Trustpilot.loadFromElement(widgetRef.current)
    }
  }, [isVisible])

  return (
    <div ref={widgetRef} className={styles.trustpilot}>
      {isVisible && (
        <>
          <Script
            src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            strategy="lazyOnload"
          />
          <div ref={widgetRef}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id={constants.trustpilot.templates.MICRO_STAR.id}
            data-businessunit-id={constants.trustpilot.businessUnitId}
            data-style-height={constants.trustpilot.templates.MICRO_STAR.height}
            data-style-width="100%"
            data-theme={theme}>
          </div>
        </>
      )}
    </div>
  )
}
